<template>
  <div>
    <subMenu key="subMenu" :activeTab="activeTab" :menuList="menuList" @handItem="handItem"></subMenu>

    <div class="main-header" style="min-width:1540px">
      <div class="nav-width">
        <div class="zhengshu">
          <div class="nav-frist2" style="margin-bottom: 5vw; padding-top: 3vw">
            <div>
              <h1 class="zhengshu-name">荣誉证书</h1>
            </div>
            <div class="b-line2" style="margin-bottom: 1vw"></div>
            <div style="color: white; margin-bottom: 5vw">
              195个证书，其中软件著作证书75项，商标证书56项，荣誉证书9项，国际顶级域名证书6项，体系3项，还有其他证书46项。
            </div>
            <div class="flex-row-between" style="margin-bottom: 3vw">
              <div v-for="(item, index) in certificateList1" :key="index + 9">
                <div>
                  <el-image style="width: 8vw; height: 6vw" :src="item.url"></el-image>
                </div>
                <div style="margin-top: 5px; color: white">{{ item.name }}</div>
              </div>
            </div>

            <div class="flex-row-between">
              <div v-for="(item, index) in certificateList2" :key="index + 9">
                <div>
                  <el-image style="width: 8vw; height: 6vw" :src="item.url"></el-image>
                </div>
                <div style="margin-top: 5px; color: white">{{ item.name }}</div>
              </div>
            </div>
          </div>
          <div>
            <el-image style="width: 90vw; height: 29vw"
              src="https://oss.yanxin7.cn/2023-08-11/08b712a732e736036761c31a00d1ee82"></el-image>
          </div>
          <div style="margin-top: 5vw">
            <el-image style="width: 62vw; height: 26vw" src="https://oss.yanxin7.cn/image/1205891 (2).png"></el-image>
          </div>
          <div style="color: white; margin-top: 1vw;font-weight:bolder;font-size: 20px;">软件著作权</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import subMenu from "@/components/sub-menu/sub-menu.vue";
import videoProduct from "../components/video/video.vue";
import newProduct from "../components/news/news.vue";
import ecosystemList from "../components/ecosystemList/ecosystemList.vue";
import partner from "../components/partner/partner.vue";
import { mapState } from "vuex";
import {
  urlCommonColumnList,
  urlSystemList,
  getCatory,
  getIndustryInformationPage,
  urlBrowseUserStatTheme1,
} from "@/api/engineering";
import LoginForm from "@/components/login-form";
export default {
  data() {
    return {
      activeName: 'home',
      fristList: [
        {
          url: 'https://oss.yanxin7.cn/2023-08-11/9e6692cd6ea96d486934155fd42d8b48'
        },
        {
          url: 'https://oss.yanxin7.cn/2023-08-11/f9702283d024f9b856171cd728058881'
        },
        {
          url: 'https://oss.yanxin7.cn/2023-08-11/9551c629bd508a278f8241c98aa095d4'
        },
        {
          url: 'https://oss.yanxin7.cn/2023-08-11/a0171ba7c112e9588589108db1517830'
        }
      ],
      fristList2: [
        {
          url: 'https://oss.yanxin7.cn/2023-08-11/6ff90f271275099ae3fcb503c4791a4c'
        },
        {
          url: 'https://oss.yanxin7.cn/2023-08-11/f8edf9daf3b1c609202f6c8697828b4d'
        },
        {
          url: 'https://oss.yanxin7.cn/2023-08-11/54c39fed4c3b0537731b717fcb9b8642'
        },
        {
          url: 'https://oss.yanxin7.cn/2023-08-11/680ac2dcf5ab5ff8e0fed351c6b89bdb'
        }
      ],
      certificateList1: [
        {
          name: "作品登记证书",
          url: "https://oss.yanxin7.cn/image/2430092 (29).png",
        },
        {
          name: "出版物经营许可证",
          url: "https://oss.yanxin7.cn/image/9541822 (30).png",
        },
        {
          name: "人力资源许可证",
          url: "https://oss.yanxin7.cn/image/2635952 (31).png",
        },
        {
          name: "诚信经营示范单位",
          url: "https://oss.yanxin7.cn/image/6375392 (1).png",
        },
        {
          name: "广播电视许可证",
          url: "https://oss.yanxin7.cn/image/8165482 (2).png",
        },
        {
          name: "企业营业执照",
          url: "https://oss.yanxin7.cn/image/8658732 (3).png",
        },
      ],
      certificateList2: [
        {
          name: "企业认证",
          url: "https://oss.yanxin7.cn/image/8546312 (21).png",
        },
        {
          name: "ICP证书",
          url: "https://oss.yanxin7.cn/image/4088762 (24).png",
        },
        {
          name: "图形商标证书",
          url: "https://oss.yanxin7.cn/image/7652322 (25).png",
        },
        {
          name: "文字注册证书",
          url: "https://oss.yanxin7.cn/image/5707782 (26).png",
        },
        {
          name: "域名证书",
          url: "https://oss.yanxin7.cn/image/4009522 (27).png",
        },
        {
          name: "3C证书",
          url: "https://oss.yanxin7.cn/image/2537012 (28).png",
        },
      ],
      showLoginForm: false,
      companyList: [],
      videoUrlList: [],
      newsList: [],
      navActive: 0,
      menuList: [
        { label: "首页", name: "home", url: "" },
        { label: "应用系统", name: "ecosystem", url: "" },
        { label: "行业资讯", name: "news", url: "" },
        { label: "荣誉证书", name: "books", url: "" },
        { label: "战略合作", name: "partner", url: "" },
        { label: "联系我们", name: "about", url: "" },
      ],
      activeTab: "books",
      serviceList: [],
      currentIndex: 0,
      intervalId: null,
    };
  },
  created() { },
  computed: {
    ...mapState("account", ["token", "userInfos"]),
    isLoggedIn() {
      return this.token && this.userInfos;
    },
    optionHover() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        // limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    routeRedirect() {
      return this.$route.query.redirect;
    },
  },
  components: {
    LoginForm,
    subMenu,
    videoProduct,
    newProduct,
    ecosystemList,
    partner,
  },
  mounted() {
    document.title = "荣誉证书";
  },
  methods: {
    handleLoginSuccess(toPage) {
      let redirectPath = this.redirectPath || toPage;
      this.resetSetItem("needLogin", JSON.stringify({ value: false }));
      this.showLoginForm = false;
      console.log("handleLoginSuccess-->toPage=" + redirectPath);
      this.redirectPath = "/";
      this.$router.push({ path: redirectPath });
    },
    handleLoginClose() {
      this.showLoginForm = false;
      this.activeTab = "home";
      if (this.redirectPath != "/") {
        this.redirectPath = "/";
        this.$router.push({ path: this.redirectPath });
      }
    },

    // 获取注册用户数
    async getUrlBrowseRegUserStatTheme1() {
      try {
        const { data } = await urlBrowseUserStatTheme1({});
        console.log("xxxxsss", data);
      } catch (error) {
        console.log("urlBrowseUserStatTheme1", error);
      }
    },
    async getIndustryInformation() {
      try {
        const { data } = await getIndustryInformationPage({
          // pageSize: 100,
          // pageNum: 1,
          current: 1,
          size: 20,
          industryInformationClassId: -1,
        });
        let records = data.records || [];
        records.forEach((element) => {
          let updateTime = element.updateTime || "";
          element.dateText = updateTime;
          if (updateTime) {
            element.dateText = updateTime.split(" ")[0];
          }
        });
        this.newsList = records;
        console.log("dasdadasd", records);
      } catch (error) {
        console.log("获取资讯报错", error);
      }
    },
    toNewDetail(item) {
      var url = "";
      if (item.industryDetailsType == 1) {
        url = item.informationDetailsUrl;
      } else {
        let baseUrl = "https://api.yanxin7.cn/api";
        if (
          window.location.origin.includes("localhost") ||
          window.location.origin.includes("dev.yanxin7.cn")
        ) {
          // 在开发环境，用开发链接
          baseUrl = "https://dev.yanxin7.cn/api";
        }
        url = baseUrl + "/v1/industry/detail?industryId=" + item.informationId;
      }
      window.open(url);
    },
    handleTabClick(tab) {
      let token = this.$store.state.account.token;
      console.log("tap------", tab);
      if (tab.name == "quation") {
        this.toPath("/home/index");
      }
      if (tab.name == "certificate") {
        if (!token) {
          this.showLoginForm = true;
          return;
        }
        this.toPath("/engineering/index");
      }

      if (tab.name == "personnel") {
        if (!token) {
          this.showLoginForm = true;
          return;
        }
        this.toPath("/recruit/index");
      }
      if (tab.name == "record") {
        if (window.location.origin.includes("localhost")) {
          window.open("https://dev.yanxin7.cn" + "/bh_web/#/")
          return
        }
        window.open(window.location.origin + "/bh_web/#/")
        return
      }

      let theUrlPrefix = "https://api.yanxin7.cn";
      if (window.location.origin.includes("trial.yanxin7.cn")) {
        theUrlPrefix = "https://trial.yanxin7.cn";
      } else if (
        window.location.origin.includes("dev.yanxin7.cn") ||
        window.location.origin.includes("localhost")
      ) {
        theUrlPrefix = "https://dev.yanxin7.cn";
      }

      if (tab.name == "project") {
        console.log("点击数字机械");
        const w = window.open("about:blank");
        // w.location.href = "https://www.bangjiantong.com/pc/mechanics.html";
        w.location.href =
          theUrlPrefix + `/erp/#/machine_login?token=${token}&loginType=7`;
      }
      if (tab.name == "creditscore") {
        console.log("点击智慧工地");
        // let url=`${window.location.origin}/erp/#/site_login?token=${token}&loginType=6`
        const w = window.open("about:blank");
        // w.location.href = theUrlPrefix + "/erp/#/smartSite/home";
        w.location.href =
          theUrlPrefix + `/erp/#/site_login?token=${token}&loginType=6`;
      }
    },

    toEnterpriseService(routerPath = "/insurance/home/index") {
      const host = window.location.host;
      let targetUrlPrefix = "";
      // TODO: 测试环境条测试，正式跳正式
      if (
        host.includes("dev.yanxin7.cn") ||
        host.includes("localhost") ||
        host.includes("192.168")
      ) {
        targetUrlPrefix = "https://dev.yanxin7.cn" + "/bxbh/#";
      } else {
        targetUrlPrefix = window.location.origin + "/bxbh/#";
      }
      window.open(targetUrlPrefix + routerPath);
    },

    toPath(url) {
      console.log("url", url);

      let vRouter = this.$router.resolve({
        path: url,
        params: {},
      });
      console.log("vRouter", vRouter);
      window.open(vRouter.href, "_blank");
    },

    // 获取建筑行业服务列表
    async geturlCommonColumnList() {
      try {
        const { data } = await urlCommonColumnList({
          appid: "pcd87133b3134a7dff",
        });
        this.$nextTick(() => {
          this.serviceList = data;
        });
      } catch (error) {
        console.log("ee", error);
      }
    },

    async getCatory() {
      try {
        const { data } = await getCatory({ appid: "pcd87133b3134a7dff" });
        this.companyList = data.filter((item) => item.type == "home");
        console.log("data=xx==>", data);
      } catch (error) {
        console.log("ee", error);
      }
    },

    async geturlSystemList() {
      try {
        const { data } = await urlSystemList({ appid: "pcd87133b3134a7dff" });
        this.videoUrlList = data?.records;
      } catch (error) {
        console.log("videoUrl", error);
      }
    },

    handItem(tab) {
      console.log("tap---->", tab.name);
      // window.open(item.params);
    },
    handNavItem(item, index) {
      this.navActive = index;
    },
  },
};
</script>

<style>
/* 进入动画 */
.v-enter-active {
  animation: move 1s;
}

/* 离开动画 */
.v-leave-active {
  animation: move 1s reverse;
}

@keyframes move {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translate(0);
  }
}
</style>

<style scoped lang="less">
.flex-between {
  display: flex;
  justify-content: space-between;
}

.main-box {
  padding-top: 30px;
  box-sizing: border-box;
  width: 1520px;
  margin-left: 50%;
  transform: translateX(-50%);
}

::v-deep .nav-width #content.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  background-color: white !important;
  color: #007ef2 !important;
}

.menu-item .el-tabs__item:hover {
  color: #ffffff !important;
  background-color: rgba(112, 154, 233, 0.3) !important;
}

.el-carousel__button {
  width: 4px;
  height: 4px;
  border-radius: 50%;
}
</style>
<style scoped lang="less">
/* 头部背景图 */
.search-for {
  width: 100%;
  height: 19vw !important;
  color: white;
  text-align: center;
  background-image: url("https://oss.yanxin7.cn/2023-08-15/d42ec178c8394c7041a57a65120dcbd1");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 1;
}

.el-tabs--border-card {
  background: transparent;
  box-shadow: none;
}

.nav-left {
  width: 1200px;
  margin: 0 auto;
}

a {
  text-decoration: none;
  color: black;
}

.nav-width {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-frist {
  width: 62vw;
}

.b-line {
  margin-top: 10px;
  width: 2vw;
  border-bottom: 1px solid #2288fc;
  margin-bottom: 5vw;
}

.company-name {
  font-weight: bold;
  font-size: 32px;
}

.flex-row-center {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-cloumn-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  text-align: start;
  margin-bottom: 10px;
  line-height: 38px;
}

.nav-top {
  display: flex;
  padding-top: 5vw;
  justify-content: space-between;
  margin-bottom: 5vw;
}

.nav-flex {
  display: flex;
  align-items: center;
}

.flex-start {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.c-8 {
  color: #888888;
}

.zhengshu {
  width: 100%;
  height: 103vw;
  background-color: #2c89f3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.zhengshu-name {
  color: white;
  font-weight: bold;
  font-size: 32px;
}

.b-line2 {
  margin-top: 10px;
  width: 2vw;
  border-bottom: 1px solid #ffffff;
  margin-bottom: 5vw;
}

.nav-frist2 {
  width: 62vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-row-between {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.originator {
  margin-top: 5vw;
  width: 100%;
  height: 21vw;
  background-image: url(https://oss.yanxin7.cn/2023-08-11/bca9bbe71fae8a86785748415028918b);
  background-repeat: no-repeat;
  position: relative;
}
</style>
